import Link, { LinkProps } from 'next/link';
import React, { FC, RefAttributes } from 'react';

import { logAnalyticsEvent } from '../../utils/analytics';
import { AnalyticsEvent } from '../../utils/types';

type Clickable = {
  onClick?: (e: React.MouseEvent<Element, MouseEvent>) => void;
};

type AnalyticsLoggerProps = AnalyticsEvent &
  Clickable & {
    children: React.ReactElement<Clickable & RefAttributes<HTMLElement>>;
  };

type AnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement>;

type Props = (LinkProps | AnchorProps) &
  AnalyticsLoggerProps & { external?: boolean };

/**
 * Use AnalyticsLink to when you need a link with button design
 * that captures events and sends analytics based on
 * user interaction.
 *
 * @param props - See Props.
 * @param props.children - Clickable children.
 * @param props.href - Link href.
 * @param props.label - Analytics event label.
 * @param props.name - Analytics event name.
 * @param props.properties - Analytics event properties.
 * @param props.shouldAddSessionDelta - Wether to add or not session delta for analytic events.
 *
 * @class
 */
export const AnalyticsLink: FC<Props> = ({
  children,
  label,
  name,
  properties,
  shouldAddSessionDelta,
  external,
  ...props
}) => {
  const analyticsLogger = (
    <AnalyticsLogger
      name={name}
      label={label}
      properties={properties}
      shouldAddSessionDelta={shouldAddSessionDelta}
    >
      {children}
    </AnalyticsLogger>
  );
  return external ? (
    <a {...(props as AnchorProps)}>{analyticsLogger}</a>
  ) : (
    <Link {...(props as LinkProps)}>{analyticsLogger}</Link>
  );
};

/**
 * Use AnalyticsLogger to wrap any clickable ReactElement
 * in order to capture events and send analytics based on
 * user interaction.
 *
 * @param props - See Props.
 * @param props.children - Clickable children.
 * @param props.label - Analytics event label.
 * @param props.name - Analytics event name.
 * @param props.properties - Analytics event properties.
 * @param props.shouldAddSessionDelta - Wether to add or not session delta for analytic events.
 *
 * @class
 */
export const AnalyticsLogger = React.forwardRef<
  HTMLElement,
  AnalyticsLoggerProps
>(
  (
    { children, label, name, properties, shouldAddSessionDelta, ...props },
    ref
  ) =>
    React.cloneElement(children, {
      ...props,
      onClick: (e: React.MouseEvent<Element, MouseEvent>) => {
        logAnalyticsEvent({
          label,
          name,
          properties,
          shouldAddSessionDelta,
        });

        children.props.onClick?.(e);
        props.onClick?.(e);
      },
      ref,
    })
);
AnalyticsLogger.displayName = 'AnalyticsLogger';
